/* General Designs */

section.dashboard {
  min-height: 100vh;
  background: transparent;
}
.primary-title {
  color: #000b8c;
  font-size: 2.5rem;
  font-weight: 500;
}

.primary-sub-title {
  color: #343434;
  font-size: 1.1rem;
  font-weight: 500;
  max-width: 50rem;
  margin: auto;
}

.ant-row {
  margin: 0 !important;
}

button.ant-btn {
  line-height: normal;
  height: auto;
  padding: 0.9rem;
  font-size: 1rem;
}

button.ant-btn > .anticon {
  line-height: 0;
}

button {
  outline: none;
}

button.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

button.ant-btn-primary {
  background: #daa900;
  border: 1px solid #daa900;
  min-width: 176px;
}

button.ant-btn-primary:hover,
button.ant-btn-primary:focus {
  background: #daa900;
  border: 1px solid #daa900;
  opacity: 0.7;
}

button.ant-btn-link {
  color: #343434;
  border: 1px solid #daa900;
  color: #daa900;
}

button.ant-btn-link:hover,
button.ant-btn-link:focus {
  border: 1px solid #daa900;
  color: #fff;
  background: #daa900;
  opacity: 0.6;
}

.ant-btn.slick-arrow {
  padding: 0;
}

.ant-radio-wrapper {
  margin-right: 30px !important;
}

.ant-radio-inner {
  background: transparent !important;
  border: 1px solid #daa900 !important;
}

.ant-radio-inner::after,
.ant-radio-checked .ant-radio-inner {
  background: #daa900 !important;
}

.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked .ant-radio-inner {
  background: #daa900 !important;
  border: 1px solid #daa900 !important;
}

input.ant-input {
  font-size: 1rem;
}

input.ant-time-picker-input {
  line-height: normal;
  height: auto;
  padding: 0.7rem;
}

div.ant-select-disabled svg {
  display: none;
}

div.disabled {
  background: #f5f5f5;
}

div.ant-select-disabled .ant-select-selection__rendered {
  color: #343434;
  font-weight: 500 !important;
}

span.ant-input-group-addon .ant-select-selection__rendered {
  margin-right: 11px;
}

div.ant-select-selection__placeholder {
  font-weight: 500 !important;
}

input.ant-input-disabled {
  color: #343434 !important;
}

div.ant-select-selection-selected-value {
  text-overflow: initial;
  width: 100% !important;
}

li.ant-select-dropdown-menu-item {
  text-overflow: unset;
}

div.ant-select-selection,
div.ant-select-selection__rendered {
  line-height: normal;
  height: auto;
}

div.ant-select-selection {
  padding: 1rem 0;
  font-size: 1rem;
}

div.ant-select-selection svg {
  color: #000b8c;
}

div.ant-select-selection:hover {
  border: 1px solid #daa900;
}

div.ant-select-selection:focus {
  box-shadow: 0 0 5px 0 #daa900;
  border: 1px solid #daa900;
}

.slider-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.95rem 11px;
  font-size: 0.95rem;
  font-weight: 300;
  color: inherit !important;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  transition: 0.3s ease-in-out;
}

.slider-dropdown:hover {
  border: 1px solid #daa900;
}

.slider-dropdown svg {
  color: #000b8c;
  font-size: 1rem;
  transition: 0.3s ease-in-out;
}

.slider-dropdown:focus svg {
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}

.slider-filter .ant-slider-rail {
  background: #ccc !important;
}

.slider-filter .ant-slider-dot {
  border-color: #979797;
  background: #ccc;
}

.slider-filter .ant-slider-dot-active {
  display: none;
}

.slider-filter .ant-slider-mark-text {
  color: #343434;
  font-size: 0.6rem;
}

.slider-filter .ant-slider-mark-text-active {
  color: #daa900;
}

.slider-filter .ant-slider-handle {
  height: 1rem;
  width: 1rem;
  margin-top: -0.4rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

span.ant-input-group-addon {
  background: #fff !important;
  padding: 0;
  border: 1px solid #ccc;
  border-right: none !important;
}

input.ant-input:hover {
  border: 1px solid #daa900;
}

input.ant-input:focus {
  border: 1px solid #daa900;
  box-shadow: 0 0 5px 0 #daa900;
}

textarea.ant-input:hover {
  border: 1px solid #daa900;
}

textarea.ant-input {
  height: 10rem !important;
}

textarea.ant-input:focus {
  border: 1px solid #daa900;
  box-shadow: 0 0 5px 0 #daa900;
}

div.ant-select-selection__placeholder {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 300;
}

div.ant-card {
  color: #343434;
}

div.ant-card-hoverable {
  border: 2px solid #000b8c;
  color: #000b8c;
  border-radius: 6px;
  height: 25rem;
}

div.ant-card-hoverable:hover {
  border: 2px solid #000b8c;
  background: #000b8c;
  color: #fff;
}

div.ant-card-hoverable .ant-card-body {
  height: inherit;
  padding: 2rem 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.ant-card-hoverable .ant-btn-primary:hover {
  opacity: 1;
}

div.ant-carousel .slick-dots-bottom {
  bottom: -2rem;
}

div.ant-carousel .slick-dots li {
  margin: 0 0.5rem;
}

div.ant-carousel .slick-dots li button {
  height: 0.2rem;
  width: 2rem;
  background: #d8d8d8;
  opacity: 1;
}

div.ant-carousel .slick-dots li.slick-active button {
  background: #000b8c;
  height: 0.2rem;
  width: 2.5rem;
}

div.ant-carousel .slick-slide {
  opacity: 0.3;
  transition: 0.2s ease-in-out;
}

div.ant-carousel .slick-slide img {
  display: inline;
}

div.ant-carousel .slick-current,
div.ant-carousel .slick-slide:hover {
  opacity: 1;
}

div.ant-back-top {
  background: #daa900;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5rem;
  line-height: normal;
  height: auto;
  width: auto;
  padding: 0.8rem;
  transition: 0.2s ease-in-out;
}

div.ant-back-top:hover {
  box-shadow: 0 0 10px 0 #777;
}

span.ant-input-search .ant-input-wrapper {
  padding: 0;
  line-height: normal;
  height: 3.5rem;
  font-size: 0.9rem;
}

span.ant-input-search .ant-input {
  padding: 0 0.5rem;
  line-height: normal;
  height: inherit;
  font-size: inherit;
}

span.ant-input-group-addon {
  height: inherit;
  background: #626584;
  font-size: inherit;
}

span.ant-input-search .ant-btn {
  padding: 0 2rem;
  line-height: normal;
  height: inherit;
  min-width: 0;
  border: none;
  font-size: inherit;
  background: inherit;
}

div.ant-slider-track {
  background: #daa900 !important;
}

div.ant-slider-handle {
  border: solid 2px #daa900 !important;
  background: linear-gradient(130deg, #fffaea 30%, #daa900) no-repeat center;
  height: 2rem;
  width: 2rem;
  margin-top: -0.9rem;
}

div.ant-tooltip-inner {
  border: solid 1px #daa900 !important;
  background: linear-gradient(130deg, #fffaea 30%, #daab006e) no-repeat center;
  color: #daa900;
  font-weight: 500;
}

div.ant-slider-handle:focus {
  box-shadow: 0 0 10px 0 #daa900;
}

div.ant-divider {
  margin: 1rem 0;
}

div.ant-divider-vertical {
  height: inherit;
  margin: 0;
}

.label {
  color: #aab2ba;
  font-weight: 700;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.label-description {
  font-size: 1rem;
  color: #343434;
  font-weight: 700;
}

.status {
  border-radius: 5px;
  font-size: 0.95rem;
  font-weight: 700;
  padding: 0.2rem 0.5rem;
  min-width: 6rem;
  text-align: center;
}

span.ant-input-search .ant-btn:hover {
  background: #daa900;
  opacity: 1;
}

.lazy-load-image-background {
  height: 100%;
  width: 100%;
}

div.ant-modal {
  width: 75rem !important;
  height: 30rem;
  top: 3rem;
}

div.ant-modal-content {
  border-radius: 8px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  border: solid 1px #dbdbdb;
  background: linear-gradient(180deg, #ffffff 0%, #fffcf2 100%);
}

div.ant-modal .ant-modal-body {
  padding: 0;
}

div.ant-modal .ant-modal-close svg {
  color: #000b8c;
  font-size: 1.3rem;
}

div.background-overlay {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
}

div.ant-form-explain {
  position: absolute;
  bottom: -2rem;
  left: 0;
  font-size: 0.9rem;
}

div.required-label {
  color: #e02020;
  font-size: 0.85rem;
}

div.ant-form-item-label label {
  font-weight: 500;
  font-size: 0.95rem;
}

.ant-list .ant-spin-container > .ant-row {
  display: flex;
  flex-wrap: wrap;
}

.ant-list .ant-list-pagination .ant-pagination-item {
  border-color: #daa900;
}
.ant-list .ant-list-pagination .ant-pagination-item a {
  color: #daa900;
}

.ant-list .ant-list-pagination .ant-pagination-item-active,
.ant-list .ant-list-pagination .ant-pagination-item:hover,
.ant-list .ant-list-pagination .ant-pagination-item-link:hover {
  border-color: 1px solid #daa900;
  background: #daa900;
}

.ant-list .ant-list-pagination .ant-pagination-item-active a,
.ant-list .ant-list-pagination .ant-pagination-item:hover a,
.ant-list .ant-list-pagination .ant-pagination-item-link:hover {
  color: #fff;
}

.ant-list .ant-list-pagination .ant-pagination-item-link {
  color: #daa900;
  border-color: #daa900 !important;
}

.ant-list .ant-list-pagination .ant-pagination-item-ellipsis {
  color: #daa900;
}

.ant-list .ant-list-pagination .ant-pagination-item-link-icon {
  color: #daa900;
}

.ant-list
  .ant-list-pagination
  .ant-pagination-disabled
  .ant-pagination-item-link {
  opacity: 0.5;
  background: transparent;
  color: #daa900;
}

div.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 7rem;
  position: relative;
  background: #fff;
}

div.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #f58840;
}

.ant-upload .ant-upload-text {
  color: #f58840;
  font-size: 1.1rem;
}

input.ant-calendar-picker-input {
  font-size: 1rem;
  padding: 0.5rem !important;
}

div.ant-calendar-date {
  border-radius: 5px !important;
  transition: 0.3s all;
  width: auto;
  margin: 0 2px;
}

div.ant-calendar-date:hover {
  background: #daa900;
  color: #fff;
  box-shadow: 0 0 5px #daa900;
}

td.ant-calendar-selected-date .ant-calendar-date {
  background: #daa900;
  box-shadow: 0 0 5px #daa900;
  color: #fff;
  font-weight: 500;
}

td.ant-calendar-selected-day .ant-calendar-date {
  background: #daa900;
  box-shadow: 0 0 5px #daa900;
  color: #fff;
}

td.ant-calendar-selected-day:hover .ant-calendar-date {
  background: #daa900;
  box-shadow: 0 0 5px #daa900;
  color: #fff;
}

td.ant-calendar-today .ant-calendar-date {
  background: #000b8c;
  box-shadow: 0 0 5px #000b8c;
  color: #fff;
}

td.ant-calendar-today:hover .ant-calendar-date {
  background: #000b8c;
}

td.ant-calendar-disabled-cell:hover .ant-calendar-date {
  background: #f5f5f5;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.25);
}

td.ant-calendar-last-month-cell .ant-calendar-date,
td.ant-calendar-next-month-btn-day .ant-calendar-date {
  box-shadow: none;
}

td.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  border: none;
}

.mobile-back {
  display: none;
}

/* Animations */

.fade-in,
.ant-layout-content > div,
.ant-layout-content > section {
  animation: fadeIn 1s ease-out;
  z-index: 1;
}

.fade-in-up {
  animation: fadeInUp 0.8s ease-in-out;
}

.lazy-load-image-loaded {
  animation: miniFade 1s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes miniFade {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .slider-dropdown {
    font-size: 1.1rem;
  }

  .mobile-back {
    display: block;
  }

  div.background-overlay {
    display: none;
  }

  .slider-filter .ant-slider-handle {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: -0.6rem;
  }

  .slider-filter .ant-slider-mark-text {
    font-size: 0.7rem;
  }
}

/* Cookie */
div.ant-notification-notice {
  max-width: 35rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px 0 #ccc;
  padding: 1.5rem;
  margin: auto;
}

div.ant-notification-notice-message {
  font-weight: 500;
  font-size: 1rem;
}
div.ant-notification-notice-description {
  font-size: 0.9rem;
}

.ant-notification-notice .ant-btn-ghost {
  border: 1px solid #daa900;
  color: #daa900;
  padding: 0.5rem;
  line-height: normal;
  height: auto;
  min-width: 7rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.ant-notification-notice .ant-btn-ghost:hover {
  background: #daa900;
  color: #fff;
  border: 1px solid #daa900;
}

@font-face {
  font-family: "MuseoSans";
  src: url("/fonts/MuseoSans/MuseoSans_300.otf");
  font-weight: 300;
}

@font-face {
  font-family: "MuseoSans";
  src: url("/fonts/MuseoSans/MuseoSans_500.otf");
  font-weight: 500;
}

@font-face {
  font-family: "MuseoSans";
  src: url("/fonts/MuseoSans/MuseoSans_700.otf");
  font-weight: 700;
}

@font-face {
  font-family: "MuseoSans";
  src: url("/fonts/MuseoSans/MuseoSans_900.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Conglomerate";
  src: url("/fonts/Conglomerate/Conglomerate Light Italic.otf");
  font-weight: 400;
  font-style: italic;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "MuseoSans", "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1vw;
}

#root {
  height: 100%;
}

* {
  font-weight: 500;
}

@media (max-width: 1024px) {
  html,
  body {
    font-size: 1.3vw;
  }
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 2vw;
  }
}

@media (max-width: 500px) {
  html,
  body {
    font-size: 3vw;
  }
}
